import React, { FC } from 'react';

import Image from 'components/common/Image';
import { IImgItem } from './models';
import './ImgItem.scss';

const ImgItem: FC<IImgItem> = ({ alt, image }) => (
  <div className="dt-media-info-box__item">
    <Image className="dt-media-info-box__item-img" localImage={image} alt={alt} />
  </div>
);

export default ImgItem;
