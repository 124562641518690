import React, { FC } from 'react';
import classNames from 'classnames';

import ImgUmbracoItem from './ImgUmbracoItem';
import { IMediaInfoBox } from './models';
import './MediaInfoBox.scss';

const MediaInfoBox: FC<IMediaInfoBox> = ({
  children,
  isMask,
  isSwap,
  isFullWidth,
  imageCenter,
  imageCenterAlt,
  sectionLandmark,
  productImage,
  customImageToggle,
}) => {
  const classes = classNames('dt-media-info-box__holder', {
    'dt-media-info-box__holder--swap': isSwap,
    'dt-media-info-box__holder--mask': isMask,
    'dt-media-info-box__holder--100w': isFullWidth,
  });

  return (
    <section className="dt-media-info-box dt-container-wrapper" aria-label={sectionLandmark}>
      <div className={classes}>{children}</div>
      {isMask ? (
        <ImgUmbracoItem {...{ productImage, customImageToggle, imageCenter, imageCenterAlt }} />
      ) : null}
    </section>
  );
};

export default MediaInfoBox;
