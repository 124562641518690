import React, { FC, useState } from 'react';
import { useId } from 'react-id-generator';
import classNames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import { IAccordionCustom } from './models';

const AccordionCustom: FC<IAccordionCustom> = ({ data }) => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setActiveId(index !== activeId ? index : null);
  };

  const activeClass = (index: number) =>
    classNames({
      'card-header__is-active': activeId === index,
    });

  return (
    <Accordion className="dt-accordion-custom">
      {data.map(({ properties: { question, answer } }, index) => {
        const [key] = useId();

        return (
          <Card key={`dt-accordion-custom-${key}`}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={String(index)}
              className={activeClass(index)}
              onClick={() => handleToggle(index)}
            >
              <button type="button" className="accordion-opener">
                {question}
                <IconCustom icon="plus_icon" />
              </button>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={String(index)}>
              <Card.Body>
                <DangerouslySetInnerHtml html={answer} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );
};

export default AccordionCustom;
